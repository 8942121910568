<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item active>LINE 應用中心</b-breadcrumb-item>
            <b-breadcrumb-item active>群發訊息</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>
    <b-card>
      <div class="row d-flex mb-2 mb-xl-2 justify-content-between">
        <div class="col-12 col-xl-6 d-flex align-items-center">
          <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">群發訊息</h4>
        </div>
        <div
          class="
            col-12 col-xl-6
            d-flex
            justify-content-start justify-content-xl-end
          "
        >
          <b-button
            v-if="checkPermission([consts.OA_PUSH_CREATE])"
            class="mb-2 mb-xl-0 ml-2 py-2"
            variant="primary"
            :to="{
              name: 'PushMessageDetail',
              params: {
                messageID: 'create',
              },
              query: {
                branch_id: query.branch_id,
              }
            }"
            ><i class="fa fa-plus" aria-hidden="true"></i>新增訊息</b-button
          >
        </div>
      </div>

      <div class="row-searchbar d-flex mb-2 mb-md-0 justify-content-end">
        <b-select
          class="mr-3 w-25"
          :options="branchOptions"
          v-model="query.branch_id"
          @input="handlePageReset"
          :disabled="!checkPermission([consts.OA_PUSH_VIEW_BRANCH])"
        ></b-select>

        <div class="d-flex mb-3 justify-content-lg-end">
          <DateSearchPicker
            class="mr-2"
            v-model="query"
            startedPlaceholder="Start Date"
            endedPlaceholder="End Date"
            @input="handleDateChange"
          />

          <b-button @click="handlePageReset"
            ><i class="fa fa-search"></i
          ></b-button>
        </div>
      </div>

      <b-tabs :value="Number(query.status)" @input="handleChangeTab">
        <b-tab v-for="tab in tabs" :title="tab.title" :key="tab.id">
          <b-card-text>
            <div class="row">
              <div class="col-12">
                <b-table
                  striped
                  hover
                  responsive
                  :items="messages"
                  :fields="fields"
                  :busy="isFetch"
                  @sort-changed="handleSort"
                >
                  <template #table-busy>
                    <div class="text-center my-2">
                      <b-spinner class="align-middle"></b-spinner>
                    </div>
                  </template>

                  <template #cell(title)="data">
                    <router-link
                      :to="{
                        name: 'PushMessageDetailEdit',
                        params: {
                          messageID: data.item.id,
                        },
                      }"
                    >
                      <!-- <b-img
                        fluid
                        rounded="0"
                        src="https://picsum.photos/250/250/?image=54"
                        alt="Image 1"
                        class="mr-1"
                      ></b-img> -->
                      {{ data.item.title }}
                    </router-link>
                  </template>

                  <template #cell(target)="data">
                    <div>
                      {{ data.item.group_id }}
                    </div>
                  </template>
                  <template #cell(advanced)="data">
                    <div>
                      {{ data.item.advanced }}
                    </div>
                  </template>

                  <template #cell(publish_at)="data">
                    <div class="mb-1">
                      {{
                        format(
                          new Date(data.item.publish_at),
                          "yyyy-MM-dd HH:mm"
                        )
                      }}
                    </div>
                  </template>

                  <template #cell(updated_at)="data">
                    <div class="mb-1">
                      {{
                        format(
                          new Date(data.item.updated_at),
                          "yyyy-MM-dd HH:mm"
                        )
                      }}
                    </div>
                  </template>

                  <template #cell(actions)="data">
                    <b-dropdown variant="secondary">
                      <template slot="button-content">
                        <i class="mdi mdi-menu"></i>
                      </template>
                      <b-dropdown-item
                        :to="{
                          name: 'PushMessageDetail',
                          params: {
                            messageID: data.item.id,
                          },
                        }"
                      >
                        查看
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="checkPermission([consts.OA_PUSH_MODIFY])"
                        :to="{
                          name: 'PushMessageDetailEdit',
                          params: {
                            messageID: data.item.id,
                          },
                        }"
                      >
                        編輯
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="deleteMessage(data.item)"
                       v-if="checkPermission([consts.OA_PUSH_DELETE])">
                        刪除
                      </b-dropdown-item>
                    </b-dropdown>
                  </template>
                </b-table>
              </div>
            </div>

            <CustomPagination
              :currentPage="query.page"
              :totalRows="total"
              :perPage="query.per_page"
              @page-change="handlePageChange"
              @per-page-change="handlePerPageChange"
            />
          </b-card-text>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import pushMessageApi from "../../../apis/push-message";
import {mapGetters, mapState} from "vuex";
import { format } from "date-fns";
import { BTable } from "bootstrap-vue";
import { zh } from "vuejs-datepicker/dist/locale";
import "bootstrap-vue/dist/bootstrap-vue.css";
import checkPermission from "@/mixins/checkPermission";
import CustomPagination from "@/components/Page/Dashboard/CustomPagination.vue";
import { updateQueryFromRoute, updateUrl } from "@/utils/updateUrl";
import { paginationMixin } from "@/mixins/pagination";
import DateSearchPicker from "@/components/Page/Dashboard/DateSearchPicker.vue";

export default {
  mixins: [checkPermission, paginationMixin],
  components: {
    DateSearchPicker,
    BTable,
    CustomPagination,
  },
  data() {
    return {
      zh,
      isFetch: false,
      tabs: [
        {
          id: "draft",
          title: "草稿",
        },
        {
          id: "scheduled",
          title: "已預約傳送",
        },
        {
          id: "success",
          title: "已傳送",
        },
        {
          id: "fail",
          title: "無法傳送",
        },
      ],

      messages: [],
      fields: [
        {
          key: "title",
          label: "標題",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "target",
          label: "對象",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "audience_count",
          label: "受眾數",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "delivered_count",
          label: "送達數",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "unique_clicks",
          label: "不重複點擊數",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "unique_impression",
          label: "不重複觀看數",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "advanced",
          label: "進階設定",
          thClass: "text-center",
          sortable: true,
        },
        {
          key: "publish_at",
          label: "傳送時間",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "updated_at",
          label: "編輯時間",
          thClass: "text-left",
          sortable: true,
        },
        {
          key: "actions",
          label: "操作",
          thClass: "text-left",
        },
      ],
      total: 0,
      initialized: false,
      start_at: null,
      end_at: null,
      query: {
        page: 1,
        per_page: 10,
        start_at: null,
        end_at: null,
        sort_by: "updated_at",
        order_by: "desc",
        status: 0,
        branch_id: null,
      },
    };
  },
  watch: {
    queryWatcher: {
      handler() {
        if (!this.initialized) return;
        updateUrl(this.query, this.$store, this.$router);
      },
    },
    $route(to) {
      if (!this.initialized) return;
      this.query = { ...to.query };
      this.fetchMessages();
    },
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters('general', [
      'myBranches',
      'currentBranch',
    ]),

    queryWatcher() {
      return `${this.query.page}-${this.query.per_page}`;
    },
    branchOptions() {
      let options = this.myBranches
        .map(branch => {
          let branchName = `${branch.branch_code} ${branch.name}`
          return {
            value: branch.id,
            text: branchName
          }
        })
      options = [
        {
          value: null,
          text: '請選擇分店'
        },
        ...options
      ]
      return options
    },
  },

  created() {
    this.query = updateQueryFromRoute(this.$route, this.$store, this.query);
    if (this.currentBranch) {
      this.query.branch_id = this.currentBranch.id
    }
    if (this.$route.query.branch_id) {
      this.query.branch_id = this.$route.query.branch_id
    }
  },

  mounted() {
    this.fetchMessages();
    this.initialized = true;
  },

  methods: {
    format,
    async fetchMessages() {
      this.isFetch = true;

      try {
        const params = {
          ...this.query,
        };

        const { data } = await pushMessageApi.getPushMessages(params);

        this.total = data.meta.total;
        this.messages = data.data;
      } catch (error) {
        console.log("error", error);
      }
      this.isFetch = false;
    },

    handleChangeTab(tab) {
      this.query.status = tab;
      this.handlePageReset();
    },

    handleSort(ctx) {
      this.query.sort_by = ctx.sortBy;
      this.query.order_by = ctx.sortDesc ? "desc" : "asc";
      this.handlePageReset();
    },

    async deleteMessage(message) {
      this.isFetch = true;

      try {
        const newDate = new Date(message.publish_at);

        const params = {
          title: message.title,
          publish_at: `${format(newDate, "yyyy-MM-dd")}T${format(
            newDate,
            "HH:mm:ss"
          )}`,
          status: message.status,
        };

        console.log(params);

        await pushMessageApi.removePushMessage(message.id, params);
        this.$swal.fire({
          title: "成功",
          type: "success",
          text: "成功刪除 ",
        });
      } catch (error) {
        this.$swal.fire({
          title: "錯誤",
          type: "error",
          text: error,
        });
      }
      this.isFetch = false;
    },

    handleDateChange(date) {
      const formatDate = (date) => {
        if (!date) return null;
        if (typeof date === "string") return date;
        return format(date, "yyyy-MM-dd HH:mm:ss");
      };
      this.query.start_at = formatDate(date.start_at);
      this.query.end_at = formatDate(date.end_at);
    },
  },
};
</script>

<style lang="scss"></style>
